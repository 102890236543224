import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import LogoBG from '../images/logo.svg'

const LogoSVG = styled.img`
  margin-top: 15px;
  padding-bottom: 70px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 70px;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    width: 90%;
    padding: 30px 0 30px 0;
    margin: 0 auto;
  }
`

const Logo = () => (
  <Link to="/">
    <LogoSVG src={LogoBG} alt="Kindred Styles Logo" />
  </Link>
)

export default Logo
