import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const Wrapper = styled.div`
  border-radius: 5px;
  overflow: hidden;
`

const Favorite = ({ type, id, slug, favoritePhotos }) => {
  return (
    <Wrapper>
      <Link to={`/${type}s/${slug}`}>
        <Img
          fluid={favoritePhotos[0].fluid}
          style={{ height: '100%' }}
          alt={favoritePhotos[0].title}
        />
      </Link>
    </Wrapper>
  )
}

export default Favorite
