import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/Layout'
import Logo from '../components/Logo'
import Helmet from 'react-helmet'
import Container from '../components/Container'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import Favorite from '../components/Favorite'
import config from '../utils/siteConfig'

const FavoriteList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
`

const FavoriteTemplate = ({ data, pageContext }) => {
  const favorites = data.allContentfulFavorite.edges
  const { currentPage } = pageContext
  const isFirstPage = currentPage === 1

  function capitalize(str) {
    if (str.includes('-')) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    } else {
      const pos = str.indexOf('-')
      return (
        str.charAt(0).toUpperCase() +
        str.slice(1, pos - 1) +
        str.replace('-', ' ') +
        str.charAt(pos + 1).toUpperCase() +
        str.slice(pos + 2)
      )
    }
  }

  const typeTitle = capitalize(favorites[0].node.type)

  return (
    <Layout>
      <SEO />
      <Helmet>
        {isFirstPage ? (
          <title>{`${config.siteTitle} | Favorite ${typeTitle}s`}</title>
        ) : (
          <title>{`${
            config.siteTitle
          } | Favorite ${typeTitle}s - Page ${currentPage}`}</title>
        )}
      </Helmet>
      <Container>
        <Logo />
        <FavoriteList>
          {favorites.map(({ node: fav }) => (
            <Favorite key={fav.id} {...fav} />
          ))}
        </FavoriteList>
      </Container>
      <Pagination context={pageContext} />
    </Layout>
  )
}

export const query = graphql`
  query($type: String!) {
    allContentfulFavorite(filter: { type: { eq: $type } }) {
      edges {
        node {
          type
          id
          slug
          favoritePhotos {
            title
            fluid(maxWidth: 800, cropFocus: FACES, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default FavoriteTemplate
